import { useState } from 'react'

import Card from '../../../components/Card/Card'
import { FormContainer, StyledTextField, StyledMenuItem, Row } from '../StyledComponents'
import { ISetting } from "../../../stores/AccountStore";

import capitalize from 'lodash/capitalize'


interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

interface ICallTranscription {
    callTranscriptionSetting: ISetting
}

const CallTranscription = ({ callTranscriptionSetting }: ICallTranscription) => {
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
    const [message, setMessage] = useState('')

    const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setMessage(message)
        setSnackbarProps({ severity, open: true })
    }

    const saveCallTranscription = async (callTranscription: string) => {
        try {
            await callTranscriptionSetting.updateValue(callTranscription === 'true')
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    return (
        <Card title='CALL Transcription' titleAlign='left' helpText='Feature still in development.'>
            <FormContainer>
                <Row>
                    <label>Global Call Transcription</label>
                    <StyledTextField
                        sx={{ width: '40%' }}
                        select
                        disabled={true}
                        onChange={(e) => saveCallTranscription(e.target.value)}
                        defaultValue={callTranscriptionSetting.value.toString()}
                        variant='outlined' size='small'
                    >
                        <StyledMenuItem key='yes' value='true'>
                            Enabled
                        </StyledMenuItem>
                        <StyledMenuItem key='no' value='false'>
                            Disabled
                        </StyledMenuItem>
                    </StyledTextField>
                </Row>
            </FormContainer>
        </Card>
    )
}

export default CallTranscription
