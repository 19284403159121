import styled, { CSSObject } from '@emotion/styled'
import MenItem from '@mui/material/MenuItem'
import Drawer from '@mui/material/Drawer'

export const IconWrapper = styled.div`
    display: flex;
    svg {
        height: 1em;
        width: 1em;
    }
`

export const drawerWidth = 200;

const openedMixin = (theme: any): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("all", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
    '& .logout':{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& span': {
            marginLeft: '10px',
            display: 'inline-block',
            fontSize: '14px',

        }
    }
});

const closedMixin = (theme: any): CSSObject => ({
    transition: theme.transitions.create('all', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '3.8em',
    '& .logout': {
        '& span': {
            display: 'none'
        }
    }
});

export const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    height: '100%',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
    '& .logout': {
        cursor: 'pointer',
        color: '#d2d1d7;',

        '&:hover': {
            backgroundColor: '#6B6A6E',
            color: '#34333A'
        }
    },
    '& .MuiList-root': {
        height: '100%'
    },

 }));

export const MenuItem = styled(MenItem)`
    min-width: 45px;
    color: #d2d1d7;
    padding-right: 0;
    svg {
        color: white;
    }
    &:hover {
        background-color: #6B6A6E;
        opacity: 1;
    }
    &&.Mui-selected {
        background-color: #BBEF1E;
        color: #6B6A6E;
        svg {
            color: #6B6A6E;
        }
        &:hover {
            background-color: #BBEF1E;
            opacity: .7;
        }
    }

    .MuiListItemIcon-root {
        min-width: 45px;
        color:  inherit;
    }
    .MuiListItemText-root {
        text-align: left;
    }

    span {
        font-size: 14px;
        padding-right: 0;
    }
`
