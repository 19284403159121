import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useAppContext } from '../../context/AppContext'
import { useStores } from '../../stores/rootStore'

import InboundCallRecorgingComponent from './InboundCallRecorging/InboundCallRecorging'
import OutboundCallRecorgingComponent from './OutboundCallRecorging/OutboundCallRecorging'
import CallTranscriptionComponent from './CallTranscription/CallTranscription'

import TextConsentComponent from './TextConsent/TextConsent'
import CRMConsoleComponent from './CRMConsole/CRMConsole'

import { ISetting } from "../../stores/AccountStore";

import { Container, Content } from './StyledComponents'

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

const Settings = () => {
    let navigate = useNavigate()
    let stores = useStores()

    const { isAdmin, currentUser, setLoading } = useAppContext()
    const [inboundRecordingSetting, setInboundRecordingSetting] = useState({} as ISetting)
    const [outboundRecordingSetting, setOutboundRecordingSetting] = useState({} as ISetting)
    const [callTranscriptionSetting, setCallTranscriptionSetting] = useState({} as ISetting)

    const [textConsentSetting, setTextConsentSetting] = useState({} as ISetting)
    const [verbalTextingConsentSetting, setVerbalTextingConsentSetting] = useState({} as ISetting | undefined)
    const [crmConsoleVersionSetting, setCrmConsoleVersionSetting] = useState({} as ISetting)

    const [errorMsg, setErrorMsg] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })


    const getAccountWithSettings = async () => {
        try {
            setLoading(true)

            const accountId = currentUser?.account?.id;
            if (accountId) {
                await stores.accountStore.getAccountAsync(accountId, 'settings,subscription.plan,subscription.promo_code')
            }

            if (stores.accountStore.account.enableInboundRecordingSetting && stores.accountStore.account.enableOutboundRecordingSetting) {
                setOutboundRecordingSetting(stores.accountStore.account.enableOutboundRecordingSetting)
                setInboundRecordingSetting(stores.accountStore.account.enableInboundRecordingSetting)
            }

            if (stores.accountStore.account.enableCallTransictionSetting) {
                setCallTranscriptionSetting(stores.accountStore.account.enableCallTransictionSetting)
            }

            if (stores.accountStore.account.enableTextingSetting) {
                setTextConsentSetting(stores.accountStore.account.enableTextingSetting)
            }

            if (stores.accountStore.account.enableVerbalTextingConsentSetting) {
                setVerbalTextingConsentSetting(stores.accountStore.account.enableVerbalTextingConsentSetting)
            }

            if (stores.accountStore.account.crmConsoleVersionSetting) {
                setCrmConsoleVersionSetting(stores.accountStore.account.crmConsoleVersionSetting)
            }

            setLoading(false)
        } catch (error) {
            setLoading(false)
            setErrorMsg('Something went wrong')
            setSnackbarProps((state) => ({ ...state, severity: 'error', open: true }))
        }
    }

    const refreshVerbaltextingConsent = async () => {
        if (verbalTextingConsentSetting?.id) {
            const setting = await verbalTextingConsentSetting.refresh();
            setVerbalTextingConsentSetting(setting);
        }
    }

    useEffect(() => {
        if (currentUser) {
            if (!currentUser.isAdmin) {
                navigate('/dashboard', { replace: true })
            }
            getAccountWithSettings()
        }
    }, [currentUser])

    return (
        <Container>
            <Content>
                <div className='row'>
                    {inboundRecordingSetting.id && <InboundCallRecorgingComponent inboundRecordingSetting={inboundRecordingSetting}/>}
                    {textConsentSetting.id && verbalTextingConsentSetting?.id && <TextConsentComponent textConsentSetting={textConsentSetting} verbalTextingConsentSetting={verbalTextingConsentSetting} onTextConsentUpdated={refreshVerbaltextingConsent}/>}
                </div>
                <div className='row'>
                    {outboundRecordingSetting.id && <OutboundCallRecorgingComponent outboundRecordingSetting={outboundRecordingSetting}/>}
                    {crmConsoleVersionSetting.id && <CRMConsoleComponent crmConsoleVersionSetting={crmConsoleVersionSetting} />}
                </div>
                <div className='row'>
                    {callTranscriptionSetting.id && <CallTranscriptionComponent callTranscriptionSetting={callTranscriptionSetting}/>}
                </div>
            </Content>
        </Container>
    )
}

export default Settings