import { useMatch, Navigate } from 'react-router-dom'

import { User } from '../../stores/UserStore'
import { logout } from '../../hooks/authHook'

const Protected = ({isLoggedIn, currentUser, children}: { isLoggedIn: boolean, currentUser: User | null, children: any }) => {
    const matchAccounts = useMatch('/accounts')
    const matchPromoCodes = useMatch('/discount-codes')
    const orderSummary = useMatch('/order-summary')
    const billingInformation = useMatch('/billing-information')
    const matchCongratulations = useMatch('/congratulations')
    const matchCreatePassword = useMatch('/create-password')
    const matchAutopylotSetup = useMatch('/profile/setup')
    const matchBusinessSetup = useMatch('/business/setup')

    if (isLoggedIn) {
        if (currentUser?.isMasterAdmin) {
            if (!matchAccounts && !matchPromoCodes) return <Navigate to="/accounts" replace />;
        } else {
            const account = currentUser?.account
            if (currentUser && account?.subscription) {
                if (account.subscription.isPending && !account.isInternal) {
                    // order summary and billing information uses tmp login. when no password set
                    // logout when user leaves order summary page
                    if (!currentUser.confirmed && !orderSummary) {
                        logout()
                        // return <Navigate to="/" />;
                    }
                    if (currentUser.confirmed && !billingInformation) {
                        return <Navigate to="/billing-information" />;
                    }
                } else {
                    // first time login after registration
                    if (!account.businessIdNumber && currentUser.signInCount == 0) {
                        if (!matchCongratulations && !matchCreatePassword) return <Navigate to="/create-password" />
                    } else {
                        if (currentUser.didAllocation?.did) {
                            if (matchAutopylotSetup) return <Navigate to="/dashboard" />
                        } else {
                            // navigate to /profile/setup when first user created password and submit business setup
                            if (!matchBusinessSetup && !matchAutopylotSetup && !matchCreatePassword) return <Navigate to="/profile/setup" />
                        }
                    }
                }
            }
        }
    } else {
        if (orderSummary) {
            return children
        } else {
            return <Navigate to="/" replace />;
        }
    }
    return currentUser ? children : <></>;
};

export default Protected
