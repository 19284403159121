import { Component } from 'react'
import { observer } from "mobx-react";
import styled from '@emotion/styled'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';

import VolumeDownIcon from '@mui/icons-material/VolumeDown';
import VolumeMuteIcon from '@mui/icons-material/VolumeMute';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';

import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import FilterByDate from "../../components/FilterByDate/FilterByDate";
import FilterByCallLength from "../../components/FilterByCallLength/FilterByCallLength";

import moment from 'moment';

import { IContact } from "../../stores/ContactStore";

interface ITableProps {
    callStore: any,
    setLoading: (loading: boolean) => void
}

interface IColumn {
    id: string
    label: string
    format: any
    // format: (contact: any) => string
}

const StyledTable = styled(Table)`
    background-color: #fff;

    & .header {
        font-size: 12px;
        color: #6B6A6E;
        text-transform: uppercase;
        white-space: nowrap;
    }

    & tbody > tr {
        & td: {
            color: #6B6A6E;
        }
        &:hover {
            background-color: #BBEF1E;
            & > td {
                color: white;
            }
        }
    }
`

const columns = [
  {
    id: 'contact',
    label: 'Contact Name',
    format: (contact: IContact) => {
      return contact.fullName
    }
  }, {
    id: 'createdAt',
    label: 'Date/Time',
    format: (value: string) => {
      return moment(value).format('MM/DD/YY HH:mm')
    }
  }, {
    id: 'callLength',
    label: 'Length',
    format: (value: string) => {
      if(!value) {
        return '';
      }
      let seconds = parseInt(value);
      var calculatedMinutes = Math.floor(seconds / 60);
      var calculatedSeconds = seconds - calculatedMinutes * 60;

      var mDisplay = calculatedMinutes < 10 ? '0' + calculatedMinutes : calculatedMinutes;
      var sDisplay = calculatedSeconds < 10 ? '0' + calculatedSeconds : calculatedSeconds;

      return `${mDisplay}:${sDisplay}`;
    },
  }, {
    id: 'callAudio',
    label: 'Audio',
    format: (value: string) => {
      if (!!value) {
        const audio = new Audio(value)
        let play = false
        const playAudio = () => {
          if (play) {
            play = false
            audio.pause()
          } else {
            play = true
            audio.play()
          }
        }
        return <VolumeDownIcon onClick={playAudio}/>
      } else {
        return (
          <Tooltip title='no audio'>
            <VolumeMuteIcon/>
          </Tooltip>
        )
      }
    },
  }, {
    id: 'from',
    label: 'Called From',
    format: (from: string) => {
      if(from.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
        return from.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
      } else {
        return from;
      }
    }
  }, {
    id: 'to',
    label: 'Called To',
    format: (to: string) => {
      if(to.match(/(\+\d+)(\d{3})(\d{3})(\d{4})/)) {
        return to.replace(/(\+\d+)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4');
      } else {
        return to;
      }
    }
  }, {
    id: 'callType',
    label: 'Type',
    format: (call_type: string) => {
      if (call_type == 'inbound') {
        return (
          <Tooltip title='inbound'>
            <CallReceivedIcon/>
          </Tooltip>
        );
      } else {
        return (
          <Tooltip title='outbound'>
            <CallMadeIcon/>
          </Tooltip>
        );
      }
    },
  }
]

interface ITablePaginationActions {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: any;
}

function TablePaginationActions(props: ITablePaginationActions) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page); // -1
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 2); // +1
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

@observer export default class CallsTable extends Component<ITableProps> {
  filters: any;
  constructor(props: ITableProps) {
    super(props)
    this.filters = {
      'page[size]': 5,
      'page[number]': 1,
      include: 'contact'
    }
  }

  componentDidMount() {
    this.refreshCalls(this.filters)
  }

  refreshCalls = async (params: any) => {
    params.include = 'contact'
    this.props.setLoading(true)
    await this.props.callStore.getCallsAsync(params)
    this.props.setLoading(false)
  }

  handlePeriodChange = (from: any, to: any) => {
    this.filters['filter[from_date]'] = from
    this.filters['filter[to_date]'] = to
    this.refreshCalls(this.filters)
  }

  handleLengthChange = (from: any, to: any) => {
    this.filters['filter[length.lt]'] = from
    this.filters['filter[length.gt]'] = to
    this.refreshCalls(this.filters)
  }

  handleChangePage = (event: any, newPage: number) => {
    this.filters['page[number]'] = newPage;
    this.refreshCalls(this.filters)
  };

  handleChangeRowsPerPage = (event: any) => {
    this.filters['page[size]'] = parseInt(event.target.value, 10)
    this.filters['page[number]'] = 1;
    this.refreshCalls(this.filters)
  };

  render() {
    const rows = this.props.callStore.calls;
    const rowsPerPage = this.filters['page[size]'];
    const page = this.filters['page[number]'];

    const totalCount = this.props.callStore.meta.recordCount;
    const displayPagination = totalCount > rowsPerPage;

    const tableHeaders = columns.map((column: IColumn) => {
      return (
        <TableCell
          key={column.id}
          className='header'
        >
          {column.label}
        </TableCell>
      )
    })

    const tableContent = this.props.callStore.calls.map((row: any) => {
      return (
        <TableRow key={row.id}>
          {columns.map((column) => {
            const value = row[column.id];
            return (
              <TableCell key={column.id}>
                {column.format(value)}
              </TableCell>
            );
          })}
        </TableRow>
      );
    })

    return (
      <>
      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={2}>
          <FilterByDate onChange={this.handlePeriodChange}/>
        </Grid>
        <Grid item xs={2}>
          <FilterByCallLength onChange={this.handleLengthChange}/>
        </Grid>
      </Grid>
      <StyledTable>
        <TableHead>
            <TableRow>{tableHeaders}</TableRow>
        </TableHead>
        <TableBody className='tbody'>
            {tableContent}
        </TableBody>
        <TableFooter>
          <TableRow>
            {displayPagination && (<TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={7}
              count={totalCount}
              rowsPerPage={rowsPerPage}
              page={(page-1)}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />)}
          </TableRow>
        </TableFooter>
      </StyledTable>
      </>
    )
  }
}
