import { useState, useEffect } from 'react'
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'

import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography';
import type { SnackbarCloseReason } from '@mui/material'

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CheckIcon from '@mui/icons-material/Check';
import HelpIcon from '@mui/icons-material/Help';

import { observer } from "mobx-react";
import { makeObservable, observable, computed, action, runInAction } from 'mobx';

import { useAppContext } from '../../context/AppContext'
import { PhoneNumberField } from '../../components/PhoneNumberInput/PhoneNumberInput'
import PopUp from '../../components/PopUp/PopUp'
import Modal from '../../components/Modal/Modal'
import Button from '../../components/Button/Button'
import Snackbar from '../../components/CustomSnackbar/CustomSnackbar'
import PublicLayout from '../../components/PublicLayout/PublicLayout'

import { capitalizeInput } from '../../utils/InputUtils'

import { Account, AccountStore, SubscriptionRelationships } from '../../stores/AccountStore'
import { checkEmail, getAutoPylotPlans, getDiscountCode } from '../../services/signup'
import { IModel, IPayload } from '../../types/storeTypes'

import { StyledMenuItem } from '../../components/UserForm/StyledComponents'
import { StyledLink } from '../Login/styled'
import {
    PaperWrapper,
    SignupPaper,
    MarketingPaper,
    StyledTypography,
    DiscountRow,
    ContentWrapper,
    Row,
    StyledTextField,
    TitleWrapper,
    Title,
    FeatureBox,
    StyledList,
    ButtonRow
} from "./StyledComponents";

import { currencyFormat } from "../../utils/NumberFormatService"
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import startCase from 'lodash/startCase'

import '../../i18n/config';
import { useTranslation } from 'react-i18next';


export interface ISignUpForm {
    email: string
    contactFirstName: string
    contactLastName: string
    contactPhoneNumber: string
    contactTitle: string
    name: string
    phoneNumber: string
    websiteUrl: string
    crm: 'dynamics' | 'salesforce' | 'netsuite' | 'sap' | 'other',
    planId: number,
    promoCodeId?: number
}

interface CustomizedState {
    userEmail: string
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

interface CrmOption {
    value: string
    label: string
    available: boolean
}

class SigUpStore {
    accountStore = new AccountStore()
    @observable
    discountCode = {} as IModel
    @observable
    discountCodeError = ''
    @observable
    autoPylotPlan = {} as IModel
    constructor(){
        makeObservable(this);
    }

    @computed
    get autoPylotPlanPrice() {
        let price = get(this.autoPylotPlan, 'attributes.monthly_price')
        if (this.percentOff > 0) {
            price = price - price*this.percentOff/100;
        }
        return price
    }

    @computed
    get percentOff() {
        if (this.discountCode?.id) {
            return get(this.discountCode, 'attributes.percent_off')*1 //to integer
        } else {
            return 0;
        }
    }

    @computed
    get discountProvider() {
        if (this.discountCode?.id) {
            return get(this.discountCode, 'attributes.name')||''
        } else {
            return ''
        }
    }

    @computed
    get couponId() {
        return this.discountCode?.id
    }

    @computed
    get isDiscountApplied() {
        return !!this.discountCode?.id
    }

    getAutoPylotPlan = async () => {
        try {
            const { data } = await getAutoPylotPlans()
            runInAction(()=> {
                this.autoPylotPlan = data.data[0]
            })
        } catch (error: any) {
            throw new Error('Something went wrong')
        }
    }

    getDiscountCode = async (code: string) => {
        try {
            const { data } = await getDiscountCode(code)
            runInAction(() => {
                this.discountCode = data.data
                this.discountCodeError = ''
            })
        } catch (error: any) {
            if (error.response?.status) {
              runInAction(() => {
                this.discountCodeError = 'Invalid code'
              })
            } else {
              throw new Error('Something went wrong')
            }
        }
    }

    createAccount = async (data: ISignUpForm) => {
        const attributes = {
            contact_first_name: data.contactFirstName,
            contact_last_name: data.contactLastName,
            contact_title: data.contactTitle,
            contact_email: data.email,
            name: data.name,
            phone_number: data.phoneNumber.replace(/[()-\s]/g, '').replace(/_/g, ''),
            website_url: data.websiteUrl,
            connected_crm: data.crm
        }
        const subscriptionRelationships = {} as SubscriptionRelationships
        subscriptionRelationships.plan = {
            data: {
                id: this.autoPylotPlan?.id,
                type: 'plans'
            }
        }
        if (this.couponId) {
            subscriptionRelationships.promo_code = {
                data: {
                    id: this.couponId,
                    type: 'promo_codes'
                }
            }
        }
        const subscription = {
            data: {
                type: 'subscriptions',
                relationships: subscriptionRelationships
            }
        }

        const relationships = {
            users: {
                data: [
                    {
                        type: 'users',
                        attributes: {
                            first_name: data.contactFirstName,
                            last_name: data.contactLastName,
                            email: data.email
                        }
                    }
                ]
            },
            subscription: subscription
        }

        const payload = {
          data: {
              type: 'accounts',
              attributes: attributes,
              relationships: relationships
          }
        } as IPayload

        const account = new Account(this.accountStore, payload)
        await account.save()
    }
}

const SignUp = observer((): JSX.Element => {
    let navigate = useNavigate()
    const { t } = useTranslation();
    const { loading, setLoading } = useAppContext()
    let [searchParams, setSearchParams] = useSearchParams();
    const [store] = useState<SigUpStore>(new SigUpStore())
    const [couponId, setCouponId] = useState('')
    const [showCrmModal, setShowCrmModal] = useState(false)
    const [confirmedCrm, setConfirmedCrm] = useState(false)
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showUserExistsModal, setUserExistsModal] = useState(false)
    const [anchorEmailElement, setAnchorEmailElement] = useState<HTMLElement | null>(null)
    const openEmailPopUp = Boolean(anchorEmailElement)
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
    const openPopUp = Boolean(anchorElement)
    const [message, setMessage] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })
    const location = useLocation()
    const state = location.state as CustomizedState
    const userEmail = state?.userEmail

    const crms = [
        {
            value: 'dynamics',
            label: 'Microsoft Dynamics',
            available: true
        },
        {
            value: 'salesforce',
            label: 'Salesforce',
            available: false
        },
        {
            value: 'netsuite',
            label: 'Netsuite',
            available: false
        },
        {
            value: 'sap',
            label: 'SAP',
            available: false
        },
        {
            value: 'other',
            label: 'Other',
            available: true
        }
    ] as Array<CrmOption>
    const [selectedCrm, setSelectedCrm] = useState<CrmOption>(crms[0])

    const { register, handleSubmit, setError, setValue, formState: { errors, isDirty, isValid }, control } = useForm<ISignUpForm>({
        mode: 'onChange',
        defaultValues: {
            email: userEmail,
            contactFirstName: '',
            contactLastName: '',
            name: '',
            websiteUrl: '',
            crm: 'dynamics'
        }
    })

    const handleEmailPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEmailElement(event.currentTarget);
    };

    const handleEmailPopoverClose = () => {
        setAnchorEmailElement(null);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorElement(null);
    };

    const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>, reason: SnackbarCloseReason) => {
        setSnackbarProps(state => ({ ...state, open: false }))
    }

    const closeSuccessModal = () => {
        setShowSuccessModal(show => !show)
        closeModal()
        navigate('/')
    }

    const closeModal = () => {
        navigate('/')
    }

    const closeCrmModal = () => {
        setConfirmedCrm(true)
        setShowCrmModal(false)
    }

    const closeUserExistsModal = () => {
        setUserExistsModal(show => !show)
    }

    const loadData = async () => {
      try {
          setLoading(true)
          await store.getAutoPylotPlan()
          const pid = searchParams.get('pid')
          if (pid) {
              setCouponId(pid)
              await store.getDiscountCode(pid)
          }
          setLoading(false)
      } catch (error: any) {
          setLoading(false)
          setMessage(error.message)
          setSnackbarProps({ severity: 'error', open: true })
      }
    }

    useEffect(() => {
       loadData()
    }, [])


    useEffect(() => {
        if (confirmedCrm) {
            const submit = handleSubmit(onSubmitAccount)
            submit()
        }
    }, [confirmedCrm])

    const onSubmitAccount = async (data: ISignUpForm) => {
        try {
            setLoading(true)
            const emailInfo = await checkEmail({ email: data.email })

            if (emailInfo.data.data.user_exists) {
                setUserExistsModal(true)
            } else if (emailInfo.data.data.domain_exists) {
                navigate('/contact-admin', { state: { userEmail: data.email } })
            } else if (!emailInfo.data.data.business_domain) {
                setError('email', {
                    type: 'manual',
                    message: 'Please enter a business email address.'
                })
            } else {
                if (selectedCrm.available || confirmedCrm) {
                    await store.createAccount(data)
                    setShowSuccessModal(true)
                } else {
                    setShowCrmModal(true)
                }
            }
            setLoading(false)
        } catch (error: any) {
            setLoading(false)

            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = capitalize(startCase(value.detail).toLowerCase());

                let pointer = value.source.pointer.split('\/').join('.');
                let fieldName = t(`account${pointer}`);
                if (fieldName !== `account${pointer}`) {
                    reason = `${fieldName} ${value.title}`;
                }

                setMessage(reason)
            });
            setSnackbarProps({ severity: 'error', open: true })
        }
    }

    const checkDiscountCode = async (code: string) => {
        store.getDiscountCode(code)
    }

    const userExistsModal = (
        <Modal
            open={showUserExistsModal}
            title="Signup for autopylot account"
            maxWidth='xs'
            fullWidth
            onClose={closeUserExistsModal}
        >
            <StyledTypography
                variant="subtitle2"
            >
                It looks like you have account. Go to the <StyledLink href="/login">Log In</StyledLink> page.
            </StyledTypography>
            <Button type='button' onClick={closeUserExistsModal}>Ok</Button>
        </Modal>
    )

    const crmIsNotAvailableModal = (
        <Modal
            open={showCrmModal}
            title="Pre-register"
            maxWidth='xs'
            fullWidth
            onClose={()=> { setShowCrmModal(false) }}
        >
            <StyledTypography
                variant="subtitle2"
            >
                Thanks for your interest in AutoPylot.
            </StyledTypography>
            <StyledTypography
                variant="subtitle2"
            >
                Support for {selectedCrm.label} is in the works and will be available soon.
            </StyledTypography>
            <StyledTypography
                variant="subtitle2"
            >
                We’ll keep your information on hand and give you first access when it becomes available.
            </StyledTypography>
            <StyledTypography
                variant="subtitle2"
            >
                If you have any specific questions, please feel free to contact us at to sales@autopylot.com.
            </StyledTypography>
            <StyledTypography
                variant="subtitle2"
            >
                Thanks.
            </StyledTypography>
            <Button type='button' onClick={closeCrmModal}>Ok</Button>
        </Modal>
    )

    const accountIsCreatedModal = (
        <Modal
            open={showSuccessModal}
            title="Signup for autopylot account"
            maxWidth='xs'
            fullWidth
            onClose={closeSuccessModal}
        >
            <StyledTypography
                variant="subtitle2"
                sx={{
                    borderTop: '1px solid rgba(107, 106, 110, 0.3)',
                    paddingTop: '15px'
                }}
            >
                A link has been sent to the email provided. Please follow the link to finish signing up for your AutoPylot account.
            </StyledTypography>
            <Button type='button' onClick={closeSuccessModal}>Ok</Button>
        </Modal>
    )

    return (
        <PublicLayout>
            {crmIsNotAvailableModal}
            {accountIsCreatedModal}
            {userExistsModal}

            <PaperWrapper>
                <MarketingPaper elevation={0}>
                    <Typography variant="h4" sx={{marginBottom: '30px'}}>
                        AutoPylot Unlimited
                    </Typography>
                    <Typography variant="h6" className="solving">
                        Solving the most frustrating problem with your CRM
                    </Typography>
                    <Typography variant="h4">
                        Automate Sales Productivity
                    </Typography>
                    <FeatureBox>
                        <Typography variant="h6" className="features-list" align="left">
                            The AutoPylot Solution includes:
                        </Typography>
                        <StyledList>
                            <ListItem>
                                <CheckIcon />
                                <ListItemText primary="AutoPylot mobile app with dedicated phone number" />
                            </ListItem>
                            <ListItem>
                                <CheckIcon />
                                <ListItemText primary="Automatic logging of sales calls and activities" />
                            </ListItem>
                            <ListItem>
                                <CheckIcon />
                                <ListItemText primary="Dictate meeting notes and action items" />
                            </ListItem>
                            <ListItem>
                                <CheckIcon />
                                <ListItemText primary="Capture recorded audio calls" />
                            </ListItem>
                            <ListItem>
                                <CheckIcon />
                                <ListItemText primary="Integrates with your existing CRM system" />
                            </ListItem>
                            <ListItem>
                                <CheckIcon />
                                <ListItemText primary="Unlimited users at no charge for 30 days" />
                            </ListItem>
                            <ListItem>
                                <CheckIcon />
                                <ListItemText primary="Unlimited minutes" />
                            </ListItem>
                        </StyledList>
                    </FeatureBox>
                    <br/>
                    <hr />
                    <Typography variant="h6" className="price">
                        {currencyFormat(store.autoPylotPlanPrice)} <span className='unit'>per month/per user</span>
                    </Typography>
                    <DiscountRow>
                        <label htmlFor='discountCode'>Discount Code</label>
                        <StyledTextField
                            id="discountCode"
                            value={couponId}
                            onChange={(e) => setCouponId(e.target.value)}
                            sx={{width: '40%'}}
                            variant='outlined' size='small'
                            error={!!store.discountCodeError}
                            helperText={store.discountCodeError}
                        />
                        {!store.isDiscountApplied && <StyledLink href='#' onClick={(e) => { e.preventDefault(); checkDiscountCode(couponId)} }>Apply</StyledLink>}
                    </DiscountRow>
                    {store.isDiscountApplied && <span className="discount-information" dangerouslySetInnerHTML={{ __html: `You have received ${store.percentOff}% discount through ${store.discountProvider}.` }}></span>}
                    <br/>
                    <Typography variant="h6" className="more-info">
                        You can cancel at no charge within 30 days of signing up. Minimum 5 users
                    </Typography>
                </MarketingPaper>

                <SignupPaper elevation={0}>
                    <TitleWrapper>
                        <Title>
                            SIGN UP FOR AUTOPYLOT
                        </Title>
                    </TitleWrapper>
                    <ContentWrapper>
                        <form onSubmit={handleSubmit(onSubmitAccount)}>
                            <Row>
                                <label htmlFor="contactFirstName">First Name</label>
                                <StyledTextField
                                    {...register('contactFirstName', { required: 'Required Field' })}
                                    id="contactFirstName"
                                    inputProps={{
                                        autoComplete: 'off',
                                        form: {
                                            autoComplete: 'off',
                                        }
                                    }}
                                    onKeyUp={(e) => { capitalizeInput(e, 'contactFirstName', setValue) }}
                                    variant='outlined' size='small'
                                    error={!!errors?.contactFirstName}
                                    helperText={errors?.contactFirstName?.message}
                                />
                            </Row>
                            <Row>
                                <label htmlFor="contactLastName">Last Name</label>
                                <StyledTextField
                                    {...register('contactLastName', { required: 'Required Field' })}
                                    id="contactLastName"
                                    inputProps={{
                                        autoComplete: 'off',
                                        form: {
                                            autoComplete: 'off',
                                        }
                                    }}
                                    onKeyUp={(e) => { capitalizeInput(e, 'contactLastName', setValue) }}
                                    variant='outlined' size='small'
                                    error={!!errors?.contactLastName}
                                    helperText={errors?.contactLastName?.message}
                                />
                            </Row>
                            <Row>
                                <label htmlFor="role">Title / Role</label>
                                <StyledTextField
                                    {...register('contactTitle', { required: 'Required Field' })}
                                    id="role"
                                    inputProps={{
                                        autoComplete: 'off',
                                        form: {
                                            autoComplete: 'off',
                                        }
                                    }}
                                    onKeyUp={(e) => { capitalizeInput(e, 'contactTitle', setValue) }}
                                    variant='outlined' size='small'
                                    error={!!errors?.contactTitle}
                                    helperText={errors?.contactTitle?.message}
                                />
                            </Row>
                            <Row>
                                <label htmlFor="phoneNumber">Your Phone Number</label>
                                <Controller
                                    name="phoneNumber"
                                    control={control}
                                    rules={{ required: 'Required Field', validate: (value) => {  return isValidPhoneNumber(`+1${value}`)||'Invalid Phone Number' } }}
                                    render={({ field: { name, onChange } }) => (
                                        <PhoneNumberField
                                            {...register('phoneNumber', { required: 'Required Field', validate: (value) => {  return isValidPhoneNumber(`+1${value}`)||'Invalid Phone Number' }  })}
                                            id="phoneNumber"
                                            sx={{minWidth: '60%', ['.MuiOutlinedInput-root fieldset']: {borderWidth: '0px'}, ['.MuiOutlinedInput-root.Mui-focused fieldset']: {borderWidth: '0px'} }}
                                            size='small'
                                            error={!!errors?.phoneNumber}
                                            helperText={errors?.phoneNumber?.message}
                                        />
                                    )}
                                />
                            </Row>
                            <Row>
                                <label htmlFor="email">
                                    Business Email
                                    <div className='help-box'
                                        onMouseEnter={handleEmailPopoverOpen}
                                        onMouseLeave={handleEmailPopoverClose}
                                    >
                                        <HelpIcon htmlColor='#42B1F2' fontSize='small' />
                                    </div>
                                    <PopUp
                                        open={openEmailPopUp}
                                        anchorElement={anchorEmailElement}
                                        onClose={handleEmailPopoverClose}
                                    >
                                        <Typography sx={{ p: 1 }}>
                                            AutoPylot only supports enterprise email domains.
                                        </Typography>
                                    </PopUp>
                                </label>
                                <StyledTextField
                                    {...register('email', { required: 'Required Field' })}
                                    id="email"
                                    inputProps={{
                                        autoComplete: 'off',
                                        form: {
                                            autoComplete: 'off',
                                        }
                                    }}
                                    variant='outlined' size='small'
                                    error={!!errors?.email}
                                    helperText={errors?.email?.message}
                                />
                            </Row>
                            <Row>
                                <label htmlFor="businessName">Business Legal Name</label>
                                <StyledTextField
                                    {...register('name', { required: 'Required Field' })}
                                    id="businessName"
                                    inputProps={{
                                        autoComplete: 'off',
                                        form: {
                                            autoComplete: 'off',
                                        }
                                    }}
                                    onKeyUp={(e) => { capitalizeInput(e, 'name', setValue) }}
                                    variant='outlined' size='small'
                                    error={!!errors?.name}
                                    helperText={errors?.name?.message}
                                />
                            </Row>
                            <Row>
                                <label htmlFor="websiteUrl">
                                    Website URL
                                    <div className='help-box'
                                        onMouseEnter={handlePopoverOpen}
                                        onMouseLeave={handlePopoverClose}
                                    >
                                        <HelpIcon htmlColor='#42B1F2' fontSize='small' />
                                    </div>
                                    <PopUp
                                        open={openPopUp}
                                        anchorElement={anchorElement}
                                        onClose={handlePopoverClose}
                                    >
                                        <Typography sx={{ p: 1 }}>
                                            The URL must match the domain of your business email.
                                        </Typography>
                                    </PopUp>
                                </label>
                                <StyledTextField
                                    {...register('websiteUrl', { required: 'Required Field' })}
                                    id="websiteUrl"
                                    inputProps={{
                                        autoComplete: 'off',
                                        form: {
                                            autoComplete: 'off',
                                        }
                                    }}
                                    variant='outlined' size='small'
                                    error={!!errors?.websiteUrl}
                                    helperText={errors?.websiteUrl?.message}
                                />
                            </Row>
                            <Row>
                                <label>Connected CRM</label>
                                <Controller
                                    name="crm"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <StyledTextField
                                            {...register('crm', { required: 'Required Field' })}
                                            select
                                            defaultValue={value}
                                            placeholder="Please Select"
                                            onChange={onChange}
                                            variant='outlined' size='small'
                                            error={!!errors?.crm}
                                            helperText={errors?.crm?.message}
                                        >
                                            <StyledMenuItem value={undefined} />
                                            {crms.map((option) => (
                                                <StyledMenuItem key={option.value} value={option.value} onClick={() => setSelectedCrm(option)}>
                                                    {option.label}
                                                </StyledMenuItem>
                                            ))}
                                        </StyledTextField>
                                    )}
                                />
                            </Row>

                            <ButtonRow style={{ marginTop: 10 }}>
                                {loading ? <CircularProgress /> : <Button type='submit' disabled={!isValid}>Verify Email</Button>}
                            </ButtonRow>
                        </form>
                    </ContentWrapper>
                </SignupPaper>
            </PaperWrapper>
            <Snackbar open={snackbarProps.open} message={message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />
        </PublicLayout>
    )
})

export default SignUp
