import React from "react"
import { useLocation, useNavigate } from 'react-router-dom'
import MenuList from "@mui/material/MenuList"
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded'
import CreditScoreIcon from '@mui/icons-material/CreditScore';
import WorkIcon from '@mui/icons-material/Work';
import PersonIcon from "@mui/icons-material/Person";
import DashboardIcon from '@mui/icons-material/Dashboard';

import PercentIcon from '@mui/icons-material/Percent';
import PeopleSharpIcon from '@mui/icons-material/PeopleSharp';
import SettingsIcon from '@mui/icons-material/Settings';

import { useAppContext } from '../../context/AppContext'
import { IconWrapper, StyledDrawer, MenuItem } from "./styledComponents";


interface ISidebar {
    open: boolean
    handleOpen: () => void
    handleLogout: (evt: React.MouseEvent<HTMLLIElement>) => void
}

const Sidebar = ({ open, handleOpen, handleLogout }: ISidebar) => {
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { isAdmin, isMasterAdmin } = useAppContext()

    const handleNavigate = (evt: React.MouseEvent<HTMLLIElement>) => {
        const { value } = evt.currentTarget.dataset

        navigate(value || '/')
    }

    const masterAdminSidebar = (
        <MenuList>
            <MenuItem
                    data-value="accounts"
                    onClick={handleNavigate}
                    selected={pathname.includes('accounts')}
                >
                <ListItemIcon><IconWrapper><PeopleSharpIcon /></IconWrapper></ListItemIcon>
                <ListItemText>Accounts</ListItemText>
            </MenuItem>
            <MenuItem
                    data-value="discount-codes"
                    onClick={handleNavigate}
                    selected={pathname.includes('discount-codes')}
                >
                <ListItemIcon><IconWrapper><PercentIcon /></IconWrapper></ListItemIcon>
                <ListItemText>Discount Codes</ListItemText>
            </MenuItem>
        </MenuList>
    )

    const autoPylotUserSidebar = (
        <MenuList>
            <MenuItem
                    data-value="dashboard"
                    onClick={handleNavigate}
                    selected={pathname.includes('dashboard')}
                >
                <ListItemIcon><IconWrapper><DashboardIcon /></IconWrapper></ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
            </MenuItem>
            <MenuItem
                    data-value="users"
                    onClick={handleNavigate}
                    selected={pathname.includes('users')}
                >
                <ListItemIcon><IconWrapper><PersonIcon /></IconWrapper></ListItemIcon>
                <ListItemText>Users</ListItemText>
            </MenuItem>
            {isAdmin &&
                <MenuItem
                        data-value="business-details"
                        onClick={handleNavigate}
                        selected={pathname.includes('business-details')}
                    >
                    <ListItemIcon><IconWrapper><WorkIcon /></IconWrapper></ListItemIcon>
                    <ListItemText>Business Details</ListItemText>
                </MenuItem>
            }
            {isAdmin &&
                <MenuItem
                    data-value="billing"
                    onClick={handleNavigate}
                    selected={pathname.includes('billing')}
                >
                    <ListItemIcon><IconWrapper><CreditScoreIcon /></IconWrapper></ListItemIcon>
                    <ListItemText>Billing</ListItemText>
                </MenuItem>
            }
            {isAdmin &&
                <MenuItem
                    data-value="settings"
                    onClick={handleNavigate}
                    selected={pathname.includes('settings')}
                >
                    <ListItemIcon><IconWrapper><SettingsIcon /></IconWrapper></ListItemIcon>
                    <ListItemText>Settings</ListItemText>
                </MenuItem>
            }
        </MenuList>
    )

    return (
        <StyledDrawer
            open={open}
            variant="permanent"
            onClose={handleOpen}
            onMouseEnter={handleOpen}
            onMouseLeave={handleOpen}
            PaperProps={{ style: { backgroundColor: '#34333A', color: 'white', zIndex: 14000 } }}
        >

            {isMasterAdmin ? masterAdminSidebar : autoPylotUserSidebar}

            <MenuItem className="logout" onClick={handleLogout}>
                <LogoutRoundedIcon />
                <ListItemText>Logout</ListItemText>
            </MenuItem>
        </StyledDrawer>
    );
};

export default Sidebar;
