import { useState, useEffect, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { observer } from "mobx-react";

import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Card from '../../../components/Card/Card'
import Button from '../../../components/Button/Button'
import { PhoneNumberField } from '../../../components/PhoneNumberInput/PhoneNumberInput'
import PopUp from '../../../components/PopUp/PopUp'
import Modal from '../../../components/Modal/Modal'

import { AccountStore, AccountAttributes, AccountRelationships } from "../../../stores/AccountStore";
import { businessTypes } from "../../../types/businessTypes"
import { ItemOption } from '../../../types/types'
import { industries } from '../../../types/businesIndustries'
import { registrationIdentifiers } from '../../../types/registrationIdentifiers'
import { countries, usaStates, canadianProvinces } from '../../../types/countriesAndStates'

import { StyledTextField, SectionName, PopupTypography, StyledSelect, Row, StyledMenuItem, StyledAutocomplete, StyledLink, RelativeForm, PencilIconWraper, PencilIcon, StyledTypography } from './styledComponents'
import capitalize from 'lodash/capitalize'
import { capitalizeInput } from '../../../utils/InputUtils'

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

interface IBusinessInformation {
    store: AccountStore
    isLoading: boolean
}

export interface IBusinessInformationForm {
    name: string
    contactFirstName: string
    contactLastName: string
    contactEmail: string
    contactTitle: string
    billingEmail: string
    addressMainStreet: string
    addressSecondaryStreet: string
    addressCity: string
    addressState: string
    addressZip: string
    addressCountry: string
    phoneNumber: string
    websiteUrl: string
    businessType: string
    industry: string
    businessIdNumber: string
    businessEmail: string
    businessPhoneNumber: string
    registrationIdentifier: string
}


const BusinessInformation = ({store, isLoading}: IBusinessInformation) => {
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: 'success' })
    const [showContactInfo, setShowContactInfo] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const [showMissingDataExplanation, setShowMissingDataExplanation] = useState(false)
    const [message, setMessage] = useState('')
    const [businessIdPlaceholder, setBusinessIdPlaceholder] = useState('EIN or DUNS number')
    const [selectedState, setSelectedState] = useState<ItemOption | null>(null)
    const [states, setStates] = useState<Array<ItemOption>>([])
    const [anchorElement, setAnchorElement] = useState<HTMLElement | null>(null)
    const openPopUp = Boolean(anchorElement)

    const defaultValues = useMemo(() => {
        return {
            name: store.account.name,
            contactFirstName: store.account.contactFirstName,
            contactLastName: store.account.contactLastName,
            contactEmail: store.account.contactEmail,
            contactTitle: store.account.contactTitle,
            billingEmail: store.account.billingEmail,
            phoneNumber: store.account.phoneNumberFormatted,
            addressMainStreet: store.account.addressMainStreet,
            addressSecondaryStreet: store.account.addressSecondaryStreet,
            addressCity: store.account.addressCity,
            addressState: store.account.addressState,
            addressZip: store.account.addressZip,
            addressCountry: store.account.addressCountry,
            websiteUrl: store.account.websiteUrl,
            businessType: store.account.businessType,
            industry: store.account.industry,
            businessIdNumber: store.account.businessIdNumber,
            businessEmail: store.account.businessEmail,
            businessPhoneNumber: store.account.businessPhoneNumberFormatted,
            registrationIdentifier: store.account.registrationIdentifier
        }
    }, [store.account])

    const { register, handleSubmit, setValue, getValues, formState: { isDirty, isValid, errors }, control, reset } = useForm<IBusinessInformationForm>({
        mode: 'onChange',
        defaultValues: defaultValues
    })

    const missingFields = useMemo(()=> {
        const fields = []

        if (!store.account.industry) fields.push('Industry')
        if (!store.account.businessType) fields.push('Business Type')
        if (!store.account.registrationIdentifier) fields.push('Registration Identifier')
        if (!store.account.businessIdNumber) fields.push('Business ID')

        return fields.join(', ')
    }, [store.account.registrationIdentifier, store.account.businessIdNumber, store.account.industry, store.account.businessType])

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElement(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorElement(null);
    };

    const displaySnackbar = (message: string, severity: 'success' | 'error' | 'warning' | 'info') => {
        setMessage(message)
        setSnackbarProps({ severity, open: true })
    }

    const onSumbitVerification = () => {
        alert('Comming soon')
    }

    const onPencilClick = () => {
        setShowContactInfo(true)
        setShowAddress(true)
    }

    const setCountry = (country: string) => {
        setStates(store.account.addressCountry === "us" ? usaStates : canadianProvinces)
    }

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
        setStates(store.account.addressCountry === "us" ? usaStates : canadianProvinces)
    }, [store.account.addressCountry]);

    useEffect(() => {
        setSelectedState(states.find((item: ItemOption) => item.label === store.account.addressState) || null)
    }, [store.account.addressState, states]);

    const onSubmitOrganizationDeatils = async (values: IBusinessInformationForm) => {
        try {
            const attributes = {
                id: store.account.id,
                name: values.name,
                contact_first_name: values.contactFirstName,
                contact_last_name: values.contactLastName,
                contact_email: values.contactEmail,
                contact_title: values.contactTitle,
                billing_email: values.billingEmail,
                address_main_street: values.addressMainStreet,
                address_secondary_street: values.addressSecondaryStreet,
                address_city: values.addressCity,
                address_state: values.addressState,
                address_zip: values.addressZip,
                address_country: values.addressCountry,
                phone_number: values.phoneNumber.replace(/[()-\s]/g, '').replace(/_/g, ''),
                business_id_number: values.businessIdNumber,
                registration_identifier: values.registrationIdentifier,
                business_type: values.businessType,
                industry: values.industry,
                business_email: values.businessEmail,
                business_phone_number: values.businessPhoneNumber.replace(/[()-\s]/g, '').replace(/_/g, '')
            } as AccountAttributes

            await store.account.update(attributes, {} as AccountRelationships, 'settings,subscription.plan,subscription.promo_code')
        } catch (error: any) {
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                let reason = value[0];

                displaySnackbar(`${capitalize(key.replace(/_/g, ' '))} ${reason}`, 'error')
            });
        }
    }

    return (
        <>
         <Modal
                open={showMissingDataExplanation}
                title="Tell us more about your business"
                maxWidth='sm'
                fullWidth
                onClose={() => setShowMissingDataExplanation(false)}
            >
                <StyledTypography align='left'>
                    Thank you for signing up for AutoPylot! You are almost ready to start using it. There are a few more pieces of information we need about your company.
                </StyledTypography>
                <StyledTypography align='left'>
                    FCC and the telecom industry are trying hard to curb spam calls and text messages. To accomplish this goal, there is a need to validate our customer’s business identity.
                </StyledTypography>
                <StyledTypography align='left'>
                    The FCC has published new rules 
                    (<StyledLink padding={0} href='https://www.fcc.gov/call-authentication' target={'_blank'}>STIR/SHAKEN</StyledLink>) 
                    to ensure businesses receiving phone numbers are verified.
                </StyledTypography>
                <StyledTypography align='left'>
                    When a business is verified, the phone numbers provisioned in that business's AutoPylot account are trusted and, as such, would significantly reduce the possibility that calls or texts to and from such numbers would be labeled as potential spam.
                </StyledTypography>
                <StyledTypography align='left'>
                    Your accounting or finance department can provide your company’s Employer Identification Number (EIN) or Dun & Bradstreet Number (DUNS), along with industry and company type. But if you don’t have the information handy, you can complete the sign-up process and add the information later in the AutoPylot Portal under Business Details.
                </StyledTypography>
                <StyledTypography align='left' fontWeight={500}>
                    We strongly recommend you enter any information you know, related to Business Type, Industry, Registration ID and Business ID, now and to update, as soon as possible, any missing information!
                </StyledTypography>
                <StyledTypography align='left'>
                    Thank you.
                </StyledTypography>
                <StyledTypography align='left'>
                    Your AutoPylot team
                </StyledTypography>
                <Button sx={{marginTop: '20px'}} type='button' onClick={() => setShowMissingDataExplanation(false)}>Ok</Button>
            </Modal>

            <Card title='Business information' titleAlign='left'>
                {!store.isLoaded ? <CircularProgress /> : <RelativeForm onSubmit={handleSubmit(onSubmitOrganizationDeatils)}>
                    <PencilIconWraper onClick={(e) => onPencilClick()}>
                        <PencilIcon />
                    </PencilIconWraper>

                    <Grid container columnSpacing={5} >
                        <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                            <Row>
                                <label>Business Legal Name</label>
                                <StyledTextField
                                    {...register('name', { required: 'Required Field' })}
                                    onKeyUp={(e) => { capitalizeInput(e, 'name', setValue) }}
                                    variant='outlined' size='small'
                                    error={!!errors?.name}
                                    helperText={errors?.name?.message}
                                />
                            </Row>
                            <Row>
                                <label>Website URL</label>
                                <StyledTextField
                                    {...register('websiteUrl')}
                                    variant='outlined' size='small'
                                    error={!!errors?.websiteUrl}
                                    helperText={errors?.websiteUrl?.message}
                                />
                            </Row>
                            <Row>
                                <label>Business E-mail</label>
                                <StyledTextField
                                    {...register('businessEmail', { required: 'Required Field' })}
                                    variant='outlined' size='small'
                                    error={!!errors?.businessEmail}
                                    helperText={errors?.businessEmail?.message}
                                />
                            </Row>
                            <Row>
                                <label>Registration Identifier</label>
                                <Controller
                                    name="registrationIdentifier"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <StyledSelect
                                            {...register('registrationIdentifier')}
                                            defaultValue={store.account.registrationIdentifier||''}
                                            displayEmpty
                                            variant='outlined' size='small'
                                            error={!getValues('registrationIdentifier')}
                                            onChange={(event) => {
                                                const value = event.target.value
                                                const selected = registrationIdentifiers.find((item: ItemOption) => {
                                                    return item.key === value;
                                                })
                                                setBusinessIdPlaceholder(selected?.label ?? 'EIN or DUNS number')
                                                onChange(event);
                                            }}
                                            renderValue={(value: any) => {
                                                if (value.length === 0) {
                                                    return <em>Select Identifier</em>;
                                                }

                                                const selected = registrationIdentifiers.find((item: ItemOption) => {
                                                    return item.key === value;
                                                })

                                                return selected?.label ?? value;
                                            }}
                                        >
                                            {registrationIdentifiers.map((option: ItemOption) => (
                                                <StyledMenuItem key={option.key} value={option.key}>
                                                    {option.label}
                                                </StyledMenuItem>
                                            ))}
                                        </StyledSelect>
                                    )}
                                />
                            </Row>
                            <Row marginBottom={0}>
                                <label>Region of Operation</label>
                                <Controller
                                    name="addressCountry"
                                    control={control}
                                    rules={{ required: true }}
                                    render={({ field: { onChange, value } }) => (
                                        <StyledTextField
                                            {...register('addressCountry')}
                                            select
                                            defaultValue={store.account.addressCountry}
                                            onChange={(e) => setCountry(e.target.value)}
                                            variant='outlined' size='small'
                                            error={!!errors?.addressCountry}
                                            helperText={errors?.addressCountry?.message}
                                        >
                                        {countries.map((option: ItemOption) => (
                                            <StyledMenuItem key={option.key} value={option.key}>
                                                {option.label}
                                            </StyledMenuItem>
                                        ))}
                                        </StyledTextField>
                                    )}
                                />
                            </Row>
                            <Row>
                                <StyledLink>&nbsp;</StyledLink>
                            </Row>
                            <Row>
                                <label>&nbsp;</label>
                                <StyledLink onClick={() => setShowAddress(showAddress => !showAddress)}>{showAddress ? 'Hide address' : 'Business Address' }</StyledLink>
                            </Row>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                            <Row>
                                <label>Business Type</label>
                                <Controller
                                    name="businessType"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <StyledTextField
                                            {...register('businessType')}
                                            select
                                            defaultValue={store.account.businessType}
                                            variant='outlined' size='small'
                                            error={!getValues("businessType")}
                                        >
                                        {businessTypes.map((option: ItemOption) => (
                                            <StyledMenuItem key={option.key} value={option.key}>
                                                {option.label}
                                            </StyledMenuItem>
                                        ))}
                                        </StyledTextField>
                                    )}
                                />
                            </Row>
                            <Row>
                                <label>Industry</label>
                                <Controller
                                    name="industry"
                                    control={control}
                                    render={({ field: { onChange, value } }) => (
                                        <StyledTextField
                                            {...register('industry')}
                                            select
                                            defaultValue={store.account.industry}
                                            variant='outlined' size='small'
                                            error={!getValues("industry")}
                                        >
                                        {industries.map((option: ItemOption) => (
                                            <StyledMenuItem key={option.key} value={option.key}>
                                                {option.label}
                                            </StyledMenuItem>
                                        ))}
                                        </StyledTextField>
                                    )}
                                />
                            </Row>
                            <Row>
                                <label>Business Phone</label>
                                <PhoneNumberField
                                    {...register('businessPhoneNumber', { required: 'Required Field', validate: (value) => { return isValidPhoneNumber(value.match(/^\+1*/) ? value : `+1${value}`)||'Invalid Phone Number' }  })}
                                    size='small'
                                    sx={{minWidth: '60%', ['.MuiOutlinedInput-root input']: {backgroundColor: '#fff'}, ['.MuiOutlinedInput-root fieldset']: {borderWidth: '1px'}, ['.MuiOutlinedInput-root.Mui-focused fieldset']: {borderWidth: '1px', borderColor: '#54ABD9'} }}
                                    error={!!errors?.businessPhoneNumber}
                                    helperText={errors?.businessPhoneNumber?.message}
                                />
                            </Row>
                            <Row>
                                <label>Business ID</label>
                                <StyledTextField
                                    {...register('businessIdNumber')}
                                    placeholder="EIN or DUNS number"
                                    variant='outlined' size='small'
                                    error={!getValues("businessIdNumber")}
                                />
                            </Row>
                            <Row marginBottom={0}>
                                <label className='verification-status'>
                                    Business Verification
                                    {store.account.isVerificationIncomplete && <span onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose} >Missing</span>}
                                    <PopUp
                                        open={openPopUp}
                                        anchorElement={anchorElement}
                                        onClose={handlePopoverClose}
                                    >
                                        <PopupTypography sx={{ p: 1 }}>
                                            {missingFields}
                                        </PopupTypography>
                                    </PopUp>
                                </label>
                                <StyledTextField
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    value={store.account.verificationStatus}
                                    variant='outlined' size='small'
                                />
                            </Row>
                            <Row align='left'>
                                {store.account.isVerificationIncomplete && <StyledLink className='no-padding' onClick={() => setShowMissingDataExplanation(true)}>Why is Missing data Important?</StyledLink> }
                                {store.account.isReadyToSubmit && <StyledLink className='no-padding' onClick={() => onSumbitVerification()}>Submit</StyledLink> }
                            </Row>
                            <Row>
                                <label>&nbsp;</label>
                                <StyledLink onClick={() => setShowContactInfo(showContactInfo => !showContactInfo)}>{showContactInfo ? 'Hide Business Contact' : 'Business Contact' }</StyledLink>
                            </Row>
                        </Grid>
                    </Grid>

                    {showAddress && <>
                        <SectionName align="left">Business Addess</SectionName>
                        <Grid container columnSpacing={5} >
                            <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                                <Row>
                                    <label>Address 1</label>
                                    <StyledTextField
                                        {...register('addressMainStreet', { required: 'Required Field' })}
                                        onKeyUp={(e) => { capitalizeInput(e, 'addressMainStreet', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.addressMainStreet}
                                        helperText={errors?.addressMainStreet?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>Address 2</label>
                                    <StyledTextField
                                        {...register('addressSecondaryStreet')}
                                        onKeyUp={(e) => { capitalizeInput(e, 'addressSecondaryStreet', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.addressSecondaryStreet}
                                        helperText={errors?.addressSecondaryStreet?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>City</label>
                                    <StyledTextField
                                        {...register('addressCity', { required: 'Required Field' })}
                                        onKeyUp={(e) => { capitalizeInput(e, 'addressCity', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.addressCity}
                                        helperText={errors?.addressCity?.message}
                                    />
                                </Row>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                                <Row>
                                    <label>Country</label>
                                    <Controller
                                        name="addressCountry"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <StyledTextField
                                                {...register('addressCountry')}
                                                select
                                                defaultValue={store.account.addressCountry}
                                                onChange={(e) => setCountry(e.target.value)}
                                                variant='outlined' size='small'
                                                error={!!errors?.addressCountry}
                                                helperText={errors?.addressCountry?.message}
                                            >
                                            {countries.map((option: ItemOption) => (
                                                <StyledMenuItem key={option.key} value={option.key}>
                                                    {option.label}
                                                </StyledMenuItem>
                                            ))}
                                            </StyledTextField>
                                        )}
                                    />
                                </Row>
                                <Row>
                                    <label>State</label>
                                    <Controller
                                        name="addressState"
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ field: { onChange, value } }) => (
                                            <StyledAutocomplete
                                                options={states}
                                                autoHighlight
                                                getOptionLabel={(option: ItemOption | any) => option.label }
                                                noOptionsText='Select Country'
                                                defaultValue={selectedState}
                                                renderInput={(params) => (
                                                    <StyledTextField
                                                        {...params}
                                                        {...register('addressState', { required: 'Required Field' })}
                                                        name="addressState"
                                                        autoComplete="off"
                                                        variant='outlined' size='small'
                                                        error={!!errors?.addressState}
                                                        helperText={errors?.addressState?.message}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Row>
                                <Row>
                                    <label>Zip</label>
                                    <StyledTextField
                                        {...register('addressZip', { required: 'Required Field' })}
                                        variant='outlined' size='small'
                                        error={!!errors?.addressZip}
                                        helperText={errors?.addressZip?.message}
                                    />
                                </Row>
                            </Grid>
                        </Grid>
                    </>}

                    {showContactInfo && <>
                        <SectionName align="left">Contact Information</SectionName>
                        <Grid container columnSpacing={5} >
                            <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                                <Row>
                                    <label>First Name</label>
                                    <StyledTextField
                                        {...register('contactFirstName', { required: 'Required Field' })}
                                        onKeyUp={(e) => { capitalizeInput(e, 'contactFirstName', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.contactFirstName}
                                        helperText={errors?.contactFirstName?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>Last Name</label>
                                    <StyledTextField
                                        {...register('contactLastName', { required: 'Required Field' })}
                                        onKeyUp={(e) => { capitalizeInput(e, 'contactLastName', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.contactLastName}
                                        helperText={errors?.contactLastName?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>Title</label>
                                    <StyledTextField
                                        {...register('contactTitle', { required: 'Required Field' })}
                                        onKeyUp={(e) => { capitalizeInput(e, 'contactTitle', setValue) }}
                                        variant='outlined' size='small'
                                        error={!!errors?.contactTitle}
                                        helperText={errors?.contactTitle?.message}
                                    />
                                </Row>
                            </Grid>
                            <Grid item xs={12} md={6} sx={{ padding: '0 0 0 0' }}>
                                <Row>
                                    <label>Contact Email</label>
                                    <StyledTextField
                                        {...register('contactEmail', { required: 'Required Field' })}
                                        variant='outlined' size='small'
                                        error={!!errors?.contactEmail}
                                        helperText={errors?.contactEmail?.message}
                                    />
                                </Row>
                                <Row>
                                    <label>Contact Phone Number</label>
                                    <Controller
                                        name="phoneNumber"
                                        control={control}
                                        rules={{ required: 'Required Field', validate: (value) => {  return isValidPhoneNumber(`+1${value}`)||'Invalid Phone Number' } }}
                                        render={({ field }) => (
                                            <PhoneNumberField
                                                {...register('phoneNumber', { required: 'Required Field', validate: (value) => { return isValidPhoneNumber(value.match(/^\+1*/) ? value : `+1${value}`)||'Invalid Phone Number' }  })}
                                                name={field.name}
                                                size="small"
                                                sx={{minWidth: '60%', ['.MuiOutlinedInput-root input']: {backgroundColor: '#fff'}, ['.MuiOutlinedInput-root fieldset']: {borderWidth: '1px'}, ['.MuiOutlinedInput-root.Mui-focused fieldset']: {borderWidth: '1px', borderColor: '#54ABD9'} }}
                                                error={!!errors?.phoneNumber}
                                                helperText={errors?.phoneNumber?.message}
                                            />
                                        )}
                                    />
                                </Row>
                            </Grid>
                        </Grid>
                    </>}
                    <Row>
                        <Button type='submit' disabled={!isValid || !isDirty} >Save</Button>
                    </Row>
                </RelativeForm>}
            </Card>
        </>
    )
}

export default observer(BusinessInformation)
