import { makeObservable, runInAction, observable, action, computed } from 'mobx';
import { AccountService, getAccount, getAccounts, createAccount, updateAccount, updateSetting, QueryParams, fetchSetting } from '../services/accounts'
import { Model, IModel, IMeta, IAttributes, IPayload, RelationshipItem, HasOneRelationship, HasManyRelationship } from '../types/storeTypes'

import startCase from 'lodash/startCase'
import moment from 'moment'

export class AccountStore {
    accountService: any;
    constructor(){
        this.accountService = new AccountService();
    }
    @observable
    account = {} as Account;
    @observable
    accounts: Array<Account> = []
    @observable
    meta = {} as IMeta;
    @observable
    status = "initial";

    getAccountAsync = async (id: number, include?: string) => {
        try {
             const accountResponse = await getAccount(id, include)

             runInAction(() => {
                this.account = new Account(this, accountResponse.data)
                this.status = "loaded";
             });
        } catch (error) {
             runInAction(() => {
                 this.status = "error";
             });
        }
    }

    getAccountsAsync = async (params: QueryParams) => {
        try {
            const data = await this.accountService.get(params)

            runInAction(() => {
                this.accounts = []

                this.meta.pageCount = data.meta.page_count;
                this.meta.recordCount = data.meta.record_count;

                data.data.forEach((json: any) => {
                    let payload = {data: json, included: data.included as IModel[]} as IPayload;

                    let account = new Account(this, payload)
                    this.accounts.push(account)
                })
            });
        } catch (error) {
            runInAction(() => {
                this.status = "error";
            });
            throw error
       }

    }

    createAccountAsync = async (payload: IPayload) => {
        try {
             const accountResponse = await createAccount(payload)

             runInAction(() => {
                this.account = new Account(this, accountResponse.data)
                this.status = "loaded";
             });
        } catch (error) {
             runInAction(() => {
                 this.status = "error";
             });
             throw error
        }
    }

    updateAccountAsync = async (id: number, payload: IPayload) => {
        try {
            runInAction(() => {
                this.status = 'initial'
            })
            const { data } = await updateAccount(id, payload)

            runInAction(() => {
                this.account = new Account(this, data)
                this.status = "loaded";
            });
        } catch (error) {
            runInAction(() => {
                this.status = "error";
            });
            throw error
        }
    }

    @computed
    get isLoaded() {
        return this.status === 'loaded'
    }

}

export interface AccountAttributes extends IAttributes {
    id?: string;
    name: string;
    contact_first_name: string;
    contact_last_name: string;
    contact_title: string;
    contact_email: string;
    billing_email: string;
    billing_phone: string;
    business_email: string;
    social_media_url: string;
    address_main_street: string;
    address_secondary_street: string;
    address_city: string;
    address_state: string;
    address_zip: string;
    address_country: string;
    website_url: string;
    phone_number: string;
    business_phone_number: string;
    id_number?: string;
    industry?: string;
    registration_identifier?: string;
    business_id_number?: string;
    business_type?: string;
    billing_type?: string;
    stripe_token?: string;
    terms_of_service?: boolean;
    time_zone?: string;
    verification_status?: string;
    connected_crm?: string;
    created_at?: string;
    internal?: boolean
}

export interface AccountRelationships {
    subscription: HasOneRelationship;
    settings?: HasManyRelationship;
    // users: HasManyRelationship;
    // integrations: HasManyRelationship;
}

export interface IAccount extends IModel {
    id: number | undefined
    name: string | undefined
    contactFirstName: string | undefined
    contactLastName: string | undefined
    contactEmail: string | undefined
    contactTitle: string | undefined
    billingEmail: string | undefined
    phoneNumber: string | undefined
    phoneNumberFormatted: string
    businessPhoneNumber: string | undefined
    businessPhoneNumberFormatted: string
    addressMainStreet: string | undefined
    addressSecondaryStreet: string | undefined
    addressCity: string | undefined
    addressState: string | undefined
    addressZip: string | undefined
    addressCountry: string | undefined
    websiteUrl: string | undefined
    businessType: string | undefined
    industry: string | undefined
    businessIdNumber: string | undefined
    businessEmail: string | undefined
    registrationIdentifier: string | undefined
    socialMediaUrl: string | undefined
    verificationStatus: string | undefined
    createdAt: moment.Moment | undefined
    age: number | undefined
    store: AccountStore
    update: (attributes: AccountAttributes, relationships?: AccountRelationships, include?: string) => void
};

export interface ISetting extends IModel {
    updateValue: (val: boolean) => void
    refresh: () => Promise<ISetting | undefined>
    value: boolean
    rawValue: string
};

// Domain object Account.
export class Account extends Model implements IAccount {
    store
    // @observable
    attributes: AccountAttributes
    // @observable
    relationships: AccountRelationships

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        if (!store) {
            store = new AccountStore()
        }
        this.store = store
        this.attributes = payload.data.attributes
        this.relationships = payload.data.relationships
    }

    @computed
    get verificationStatus() {
        if (this.industry && this.businessType && this.registrationIdentifier && this.businessIdNumber) {
            return 'Ready to Submit'
        } else {
            return 'Incomplete'
        }
        // return startCase(this.attributes.verification_status);
    }

    @computed
    get isVerificationIncomplete() {
        return this.verificationStatus === 'Incomplete'
    }

    @computed
    get isReadyToSubmit() {
        return this.verificationStatus === 'Ready to Submit'
    }

    @computed
    get isInternal() {
        return !!this.attributes.internal
    }

    @computed
    get name() {
        return this.attributes.name;
    }

    @computed
    get contactFirstName() {
        return this.attributes.contact_first_name;
    }

    @computed
    get contactLastName() {
        return this.attributes.contact_last_name;
    }

    @computed
    get contactTitle() {
        return this.attributes.contact_title
    }

    @computed
    get contactEmail() {
        return this.attributes.contact_email;
    }

    @computed
    get phoneNumber() {
        return this.attributes.phone_number;
    }

    @computed
    get phoneNumberFormatted() {
        if(this.phoneNumber && this.phoneNumber.match(/(\d{3})(\d{3})(\d{4})/)) {
            return this.phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else {
            return this.phoneNumber;
        }
    }

    @computed
    get businessPhoneNumber() {
        return this.attributes.business_phone_number;
    }

    @computed
    get businessPhoneNumberFormatted() {
        if(this.businessPhoneNumber?.match(/(\d{3})(\d{3})(\d{4})/)) {
            return this.businessPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else {
            return this.businessPhoneNumber;
        }
    }

    @computed
    get billingPhoneNumber() {
        return this.attributes.billing_phone;
    }

    @computed
    get billingPhoneNumberFormatted() {
        if(this.billingPhoneNumber?.match(/(\d{3})(\d{3})(\d{4})/)) {
            return this.billingPhoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
        } else {
            return this.billingPhoneNumber;
        }
    }

    @computed
    get businessEmail() {
        return this.attributes.business_email;
    }

    @computed
    get registrationIdentifier() {
        return this.attributes.registration_identifier;
    }

    @computed
    get socialMediaUrl() {
        return this.attributes.social_media_url||'';
    }

    @computed
    get websiteUrl() {
        return this.attributes.website_url;
    }

    @computed
    get idNumber() {
        return this.attributes.id_number;
    }

    @computed
    get businessIdNumber() {
        return this.attributes.business_id_number;
    }

    @computed
    get businessType() {
        return this.attributes.business_type;
    }

    @computed
    get industry() {
        return this.attributes.industry;
    }

    @computed
    get addressMainStreet() {
        return this.attributes.address_main_street;
    }

    @computed
    get addressSecondaryStreet() {
        return this.attributes.address_secondary_street;
    }

    @computed
    get addressCity() {
        return this.attributes.address_city;
    }

    @computed
    get addressState() {
        return this.attributes.address_state;
    }

    @computed
    get addressZip() {
        return this.attributes.address_zip;
    }

    @computed
    get addressCountry() {
        return this.attributes.address_country;
    }

    @computed
    get holderName() {
        return '';
    }

    @computed
    get billingEmail() {
        return this.attributes.billing_email;
    }

    @computed
    get manualBillingType() {
        return this.attributes.billing_type === 'manual';
    }

    @computed
    get createdAt() {
        return moment(this.attributes.created_at)
    }

    @computed
    get age() {
        return moment().diff(this.createdAt, 'days');
    }

    @computed
    get subscription() {
        if (!this.relationships.subscription.data) {
          return null
        }

        let subscriptionId = this.relationships.subscription.data.id;
        let subscriptionData = this.included.find((item: any) => {
            return item.type === 'subscriptions' && item.id === subscriptionId;
        });
        const payload = {data: subscriptionData, included: this.included} as IPayload;

        if (subscriptionData) {
            let subscription = new Subscription(null, payload)
            return subscription;
        } else {
            return null;
        }
    }

    @computed
    get billingAddress() {
        if (!this.included) {
            return null
        }

        let addressData = this.included.find((item: any) => {
            return item.type === 'account_addresses' && item.attributes.category === 'billing';
        });
        const payload = {data: addressData, included: [] as IModel[]} as IPayload;
        if (addressData) {
            let address = new Address(null, payload)
            return address;
        } else {
            return null;
        }
    }

    @computed
    get subscriptionFee() {
        if (!this.subscription) {
            return 0
        }

        const usersNumber = parseInt(this.additionalUsersNumber?.rawValue||'0', 10)
        const total = usersNumber * this.subscription.monthlyPrice

        return total - total*this.subscription.percentOff/100
    }

    @computed
    get payload() {
      return {
          data: {
              id: this.id,
              type: 'accounts',
              attributes: this.attributes,
              relationships: this.relationships
          }
      }
    }

    save = async () => {
        await this.store.createAccountAsync(this.payload)
    }

    update = async (attributes: AccountAttributes, relationships?: AccountRelationships, include?: string) => {
        const payload = {
            include: include,
            data: {
                id: this.id,
                type: 'accounts',
                attributes: attributes,
                relationships: relationships
            }
        }

        await this.store.updateAccountAsync(this.id, payload)
    }

    settingFor = (settingName: string) => {
        if (!this.included) {
            return null
        }

        let settingData = this.included.find((item: any) => {
            return item.type === 'settings' && item.attributes.key === settingName;
        });
        const payload = {data: settingData, included: [] as IModel[]} as IPayload;

        if (settingData) {
            let setting = new Setting(null, payload)
            return setting;
        } else {
            return null;
        }
    }

    @computed
    get enableInboundRecordingSetting() {
        return this.settingFor('inbound_recording_enabled')
    }

    @computed
    get enableRequireInboundRecordingSetting() {
        return this.settingFor('require_inbound_recording_enabled')
    }

    @computed
    get enableOutboundRecordingSetting() {
        return this.settingFor('outbound_recording_enabled')
    }

    @computed
    get enableRequireOutboundRecordingSetting() {
        return this.settingFor('require_outbound_recording_enabled')
    }

    @computed
    get enableCallTransictionSetting() {
        // TODO: remove this hardcode
        let settingData = {
            id: 300,
            type: 'settings',
            attributes: {
                key: 'call_transiction_enabled',
                value: '0.0'
            }
        }
        const payload = {data: settingData, included: [] as IModel[]} as IPayload;
        return new Setting(null, payload)
        // return this.settingFor('call_transiction_enabled')
    }

    @computed
    get enableTextConsentSetting() {
        return this.settingFor('texting_consent_enabled')
    }

    @computed
    get enableTextingSetting() {
        return this.settingFor('texting_enabled')
    }

    @computed
    get enableVerbalTextingConsentSetting() {
        return this.settingFor('texting_consent_verbal_enabled')
    }

    @computed
    get crmConsoleVersionSetting() {
        // TODO: remove this hardcode
        let settingData = {
            id: 301,
            type: 'settings',
            attributes: {
                key: 'crm_console_version',
                value: '07-20-2022-4'
            }
        }
        const payload = {data: settingData, included: [] as IModel[]} as IPayload;
        return new Setting(null, payload)
        // return this.settingFor('crm_console_version')
    }

    @computed
    get additionalUsersNumber() {
        if (!this.included) {
          return null
        }

        let settingData = this.included.find((item: any) => {
            return item.type === 'settings' && item.attributes.key === 'additional.users';
        });
        const payload = {data: settingData, included: [] as IModel[]} as IPayload;

        if (settingData) {
            let setting = new Setting(null, payload)
            return setting;
        } else {
            return null;
        }
    }
}


export interface SubscriptionAttributes extends IAttributes {
    status: string,
    kind: string,
    previous_kind: string,
    created_at: string,
    expires_at: string
}

export interface SubscriptionRelationships {
    account: HasOneRelationship;
    plan: HasOneRelationship;
    promo_code?: HasOneRelationship;
}

// Domain object Subscription.
export class Subscription extends Model {
    // @observable
    attributes: SubscriptionAttributes
    // @observable
    relationships: SubscriptionRelationships

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
        this.relationships = payload.data.relationships
    }

    @computed
    get status() {
        return this.attributes.status;
    }

    @computed
    get isPending() {
        return this.status === 'pending'
    }

    @computed
    get isCancelled() {
        return this.attributes.kind === 'cancelled'
    }

    @computed
    get state() {
        switch (this.status) {
            case 'trial':
                return '30 Day Free Trial'
            case 'active':
                return 'Paid'
            case 'inactive':
                return this.attributes.previous_kind === 'paid' ? 'Paid - Cancelled' : 'Cancelled'
        }
    }

    @computed
    get plan() {
        if (!this.relationships.plan?.data) {
          return null
        }

        let planId = this.relationships.plan.data.id;
        let planData = this.included.find((item: any) => {
            return item.type === 'plans' && item.id === planId;
        });
        const payload = {data: planData, included: this.included} as IPayload;

        if (planData) {
            let plan = new Plan(null, payload)
            return plan;
        } else {
            return null;
        }
    }

    @computed
    get promoCode() {
        if (!this.relationships.promo_code?.data) {
          return null
        }

        let promo_codeId = this.relationships.promo_code.data.id;
        let promo_codeData = this.included.find((item: any) => {
            return item.type === 'promo_codes';
        });
        const payload = {data: promo_codeData, included: [] as IModel[]} as IPayload;

        if (promo_codeData) {
            let promo_code = new PromoCode(null, payload)
            return promo_code;
        } else {
            return null;
        }
    }

    @computed
    get monthlyPrice() {
        return this.plan?.monthlyPrice||0
    }

    @computed
    get percentOff() {
        return parseInt(this.promoCode?.percentOff||'0', 10)
    }

    @computed
    get createdAt() {
        return moment(this.attributes.created_at)
    }

    @computed
    get expiresAt() {
        return moment(this.attributes.expires_at)
    }
}

export interface PromoCodeAttributes extends IAttributes {
    name: string
    percent_off: string
}

// Domain object PromoCode.
export class PromoCode extends Model {
    // @observable
    attributes: PromoCodeAttributes
    // @observable
    // relationships: PromoCodeRelationships

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
    }

    @computed
    get name() {
        return this.attributes.name;
    }

    @computed
    get percentOff() {
        return this.attributes.percent_off;
    }
}

export interface PlanAttributes extends IAttributes {
    key: string
    monthly_price: number
}

// Domain object Plan.
export class Plan extends Model {
    // @observable
    attributes: PlanAttributes
    // @observable
    // relationships: PromoCodeRelationships

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
    }

    @computed
    get key() {
        return this.attributes.key;
    }

    @computed
    get name() {
        return startCase(this.key)
    }

    @computed
    get monthlyPrice() {
        return this.attributes.monthly_price;
    }
}

export interface SettingAttributes extends IAttributes {
    key: string
    value: string
}

// Domain object Setting.
export class Setting extends Model {
    // @observable
    attributes: SettingAttributes
    // @observable
    // relationships: SubscriptionRelationships

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
    }

    @action
    updateValue = async (value: boolean) => {
        this.attributes.value = value ? '1' : '0'

        if (this.id) {
          await updateSetting(this.id, this.attributes)
        }
    }

    @action
    refresh = async () => {
        if (this.id) {
          const payload = await fetchSetting(this.id);
          let setting = new Setting(null, payload);
          return setting;
        }
    }

    @computed
    get value() {
        return this.attributes.value == '1.0';
    }

    @computed
    get rawValue() {
        return this.attributes.value;
    }

}

export interface AddressAttributes extends IAttributes {
    category: string
    street: string
    street2: string
    city: string
    state: string
    zip: string
    country: string
}

export class Address extends Model {
    // @observable
    attributes: AddressAttributes

    constructor(store: any, payload: IPayload) {
        super(store, payload)
        this.attributes = payload.data.attributes
    }

    @computed
    get street() {
        return this.attributes.street;
    }

    @computed
    get street2() {
        return this.attributes.street2;
    }

    @computed
    get city() {
        return this.attributes.city;
    }

    @computed
    get state() {
        return this.attributes.state;
    }

    @computed
    get zip() {
        return this.attributes.zip;
    }

    @computed
    get country() {
        return this.attributes.country;
    }
}