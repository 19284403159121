import {makeObservable, runInAction, observable, computed} from "mobx";
import {CallService} from "../services/calls";
import {
  Model,
  IModel,
  IMeta,
  IAttributes,
  IPayload,
  HasOneRelationship,
} from "../types/storeTypes";
import {Contact} from "./ContactStore";

import moment from "moment";

export class CallStore {
  callService: any;
  constructor() {
    this.callService = new CallService();
    makeObservable(this);
  }
  @observable
  calls: Array<Call> = [];
  @observable
  meta = {} as IMeta;
  status = "initial";
  searchQuery = "";

  getAllCallsAsync = async (params: any) => {
    params["page[size]"] = 50;
    await this.getCallsAsync(params);
    if (this.meta.pageCount > 1) {
      let currentPage = 1;
      while (currentPage < this.meta.pageCount) {
        currentPage++;
        params["page[number]"] = currentPage;
        await this.getCallsAsync(params, true);
      }
    }
  };

  getCallsAsync = async (params: any, keepPreviousItems?: boolean) => {
    try {
      // const urlParams = new URLSearchParams(Object.entries(params));
      const data = await this.callService.get(params);

      runInAction(() => {
        if (!keepPreviousItems) {
          this.calls = [];
        }

        this.meta.pageCount = data.meta.page_count;
        this.meta.recordCount = data.meta.record_count;

        data.data.forEach((json: any) => {
          let payload = {
            data: json,
            included: data.included as IModel[],
          } as IPayload;

          let call = new Call(this, payload);

          this.calls.push(call);
        });
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
  };

  @computed
  get totalCallLength() {
    let totalSeconds = this.calls.reduce(
      (partialSum, call) => partialSum + call.callLength,
      0
    );
    return Math.round((totalSeconds * 100) / 60) / 100;
  }

  @computed
  get totalCalls() {
    return this.calls.length;
  }

  @computed
  get groupedMinutesByDate() {
    return this.calls.reduce((hash: any, call) => {
      let day = call.createdAt.split("T")[0];

      if (!hash[day]) {
        hash[day] = 0;
      }

      hash[day] += call.callLength / 60;

      return hash;
    }, {});
  }

  @computed
  get groupedCountByDate() {
    return this.calls.reduce((hash: any, call) => {
      let day = moment(call.createdAt).format("YYYY-MM-DD");

      if (!hash[day]) {
        hash[day] = 0;
      }

      hash[day] += 1;

      return hash;
    }, {});
  }
}

export interface CallAttributes extends IAttributes {
  created_at: string;
  call_length: number;
  call_audio: string;
  from: string;
  to: string;
  call_type: string;
  status: string;
}

export interface ICall extends IModel {}

export interface CallRelationships {
  contact: HasOneRelationship;
}

// Domain object Call.
export class Call extends Model {
  attributes: CallAttributes;
  relationships: CallRelationships;

  constructor(store: any, payload: IPayload) {
    super(store, payload);
    this.attributes = payload.data.attributes;
    this.relationships = payload.data.relationships;
  }

  @computed
  get createdAt() {
    return this.attributes.created_at;
  }

  @computed
  get callLength() {
    return this.attributes.call_length;
  }

  @computed
  get callAudio() {
    return this.attributes.call_audio;
  }

  @computed
  get hasAudio() {
    return !!this.attributes.call_audio;
  }

  @computed
  get from() {
    return this.attributes.from;
  }

  @computed
  get to() {
    return this.attributes.to;
  }

  @computed
  get callType() {
    return this.attributes.call_type;
  }

  @computed
  get isVoiceMail() {
    return this.status === "missed" && this.hasAudio;
  }

  @computed
  get isAccepted() {
    return this.status === "started";
  }

  @computed
  get isMissed() {
    return this.status === "missed" && !this.hasAudio;
  }

  @computed
  get status() {
    return this.attributes.status;
  }

  @computed
  get contact() {
    let contact_id = this.relationships.contact.data.id;
    let contactData = this.included.find((item: any) => {
      return item.type === "contacts" && item.id === contact_id;
    });
    const payload = {data: contactData, included: [] as IModel[]} as IPayload;

    if (contactData) {
      let contact = new Contact(null, payload);
      return contact;
    } else {
      return null;
    }
  }
}
