import React from 'react'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import moment from 'moment'

interface FilterByDateProps {
  onChange: any
}

const datePeriods = [
  {
    key: 'today',
    label: 'Today only',
    from: () => {
      return moment().format('DD-MM-YYYY')
    },
    to: () => {
      return moment().format('DD-MM-YYYY')
    }
  },
  {
    key: 'yesterday',
    label: 'Since Yesterday',
    from: () => {
      return moment().subtract(1, 'day').startOf('day').format('DD-MM-YYYY')
    },
    to: () => {
      return moment().format('DD-MM-YYYY')
    }
  },
  {
    key: 'last_3_days',
    label: 'Last 3 Days',
    from: () => {
      return moment().subtract(2, 'day').startOf('day').format('DD-MM-YYYY');
    },
    to: () => {
      return moment().format('DD-MM-YYYY')
    }
  },
  {
    key: 'last_7_days',
    label: 'Last 7 Days',
    from: () => {
      return moment().subtract(6, 'day').startOf('day').format('DD-MM-YYYY');
    },
    to: () => {
      return moment().format('DD-MM-YYYY')
    }
  },
  {
    key: 'last-30-days',
    label: 'Last 30 Days',
    from: () => {
      return moment().subtract(29, 'day').startOf('day').format('DD-MM-YYYY');
    },
    to: () => {
      return moment().format('DD-MM-YYYY');
    }
  },
  {
    key: 'last_month',
    label: 'Last Month',
    from: () => {
      return moment().subtract(1, 'months').startOf('month').startOf('day').format('DD-MM-YYYY')
    },
    to: () => {
      return moment().subtract(1, 'months').endOf('month').endOf('day').format('DD-MM-YYYY');
    }
  },
  {
    key: 'ytd',
    label: 'Year to Date (YTD)',
    from: () => {
      return moment().startOf('year').format('DD-MM-YYYY');
    },
    to: () => {
      return moment().format('DD-MM-YYYY')
    }
  }
]

const FilterByDate = (props: FilterByDateProps): JSX.Element => {
  const [period, setPeriod] = React.useState('today');
  const { onChange } = props

  const handleChange = (event: any) => {
    let selectedPeriod = datePeriods.find((period: any) => {
      return period.key === event.target.value;
    })

    if (selectedPeriod) {
      onChange(selectedPeriod.from(), selectedPeriod.to())
    } else {
      onChange(null, null)
    }
    setPeriod(event.target.value);
  }

  return (
    <FormControl fullWidth>
      <Select
        value={period}
        onChange={handleChange}
      >
        <MenuItem key='none' value="none">
          <em>Filter by Date</em>
        </MenuItem>
        {datePeriods
          .map((period: any) => {
            return (
              <MenuItem key={period.key} value={period.key}>{period.label}</MenuItem>
            )
          })
        }
      </Select>
    </FormControl>
  )
}

export default FilterByDate
