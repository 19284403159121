import { Container, StyledLink } from './styledComponents'

interface IFooter {
    fullWidth?: boolean
}

const Footer = ({ fullWidth = true }: IFooter) => {
    return (
        <Container {...{ fullWidth }}>
            <div>Copyright @ AutoPylot Technologies Corp. 2023</div>
            <div>
                <StyledLink href="https://www.autopylot.com/privacy" target={'_blank'}>Privacy Policy</StyledLink>
                <StyledLink style={{marginRight: 0}} href="https://www.autopylot.com/terms" target={'_blank'}>Terms of Service</StyledLink>
            </div>
        </Container>
    )
}

export default Footer