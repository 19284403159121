import { useNavigate, useSearchParams } from 'react-router-dom'
import { useState, useEffect } from 'react'

import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'
import Typography from '@mui/material/Typography'
import CheckIcon from '@mui/icons-material/Check';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Button from '../../components/Button/Button'

import PublicLayout from '../../components/PublicLayout/PublicLayout'

import { useForm } from 'react-hook-form'
import CircularProgress from '@mui/material/CircularProgress'
import { useAppContext } from '../../context/AppContext'
import Snackbar from '../../components/CustomSnackbar/CustomSnackbar'

import {
    Row,
    ButtonRow,
    OrderPaper,
    TitleWrapper,
    Title,
    ContentWrapper,
    AgreementPaper,
    SubsriptionDetailsBox,
    StyledIconButton,
    StyledInputAdornment,
    UsersNumberField
} from './styledComponents'


import { observer } from "mobx-react";
import { makeObservable, observable, computed, action, runInAction } from 'mobx';
import { UserStore } from '../../stores/UserStore'
import { AccountAttributes, AccountRelationships } from '../../stores/AccountStore'
import { getAgreement, getUserByToken } from '../../services/signup'
import { IModel } from '../../types/storeTypes'

import { saveInLocal } from '../../utils/LocalStorageService'
import { getCurrentUser } from '../../services/users'

import { currencyFormat } from "../../utils/NumberFormatService"
import capitalize from 'lodash/capitalize'

export interface IOrderForm {
    termsOfService: string
    usersNumber: number
}

interface CustomSnackbarProps {
    open: boolean
    severity: 'success' | 'error' | 'warning' | 'info'
}

class OrderStore {
    userStore = new UserStore()
    @observable
    promoCode = {} as any
    @observable
    user = {} as IModel
    @observable
    agreement = ''
    @observable
    monthlyPrice = 0
    @observable
    usersNumber = 5
    constructor(){
        makeObservable(this);
    }

    getUserByToken = async (token: string) => {
        try {
            const { data } = await getUserByToken(token)

            saveInLocal('token', data.token)
            const user = getCurrentUser()
            await this.userStore.getUserAsync(user.id, 'account,account.subscription.promo_code,account.subscription.plan')

            runInAction(() => {
                if (this.userStore.user.account?.subscription?.plan?.monthlyPrice) {
                    this.monthlyPrice = this.userStore.user.account?.subscription?.plan?.monthlyPrice
                }
                if (this.userStore.user.account?.subscription?.promoCode) {
                    this.promoCode = this.userStore.user.account?.subscription?.promoCode
                }

                this.user = this.userStore.user
            })
        } catch (error: any) {
            if(error.response.status === 404) {
                return null
            } else {
                throw new Error('Something went wrong')
            }
        }
    }

    @action
    getAgreementContent = async () => {
        try {
            const { data } = await getAgreement()
            this.agreement = data[0].content
        } catch (error: any) {
            throw new Error('Something went wrong')
        }
    }

    @action
    setAdditionalUsers = (usersNumber: number) => {
        this.usersNumber = usersNumber
    }

    @action
    submitOrderSummary = async () => {
        console.log('submitOrderSummary');
        try {
            const settings = {
                data: [
                    {
                        type: 'settings',
                        attributes: {
                            key: 'additional.users',
                            value: this.usersNumber
                        }
                    }
                ]
            }

            const subscription = {
                data: {
                    id: this.userStore.user.account?.subscription?.id,
                    type: 'subscriptions'
                }
            }
            await this.userStore.user.account?.update({terms_of_service: true} as AccountAttributes , { subscription: subscription, settings: settings } as AccountRelationships)
        } catch (error: any) {
            throw new Error('Something went wrong')
        }
    }

    @computed
    get discountProvider() {
        return this.promoCode?.name
    }

    @computed
    get percentOff () {
        if (this.promoCode?.percentOff) {
            return parseInt(this.promoCode.percentOff, 10)
        } else {
            return 0
        }
    }

    @computed
    get pricePerUser() {
        return this.monthlyPrice - this.monthlyPrice*this.percentOff/100
    }

    @computed
    get totalPrice() {
        let total = this.usersNumber*this.monthlyPrice

        return total - total*this.percentOff/100
    }

    @computed
    get agreementContent() {
        return this.agreement
    }
}

const OrderSummary = observer((): JSX.Element => {
    let [searchParams, setSearchParams] = useSearchParams();
    let navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [snackbarProps, setSnackbarProps] = useState<CustomSnackbarProps>({ open: false, severity: "success" })
    const [store] = useState<OrderStore>(new OrderStore())
    const { loading, setLoading, refreshCurrentUser } = useAppContext()
    const { register, handleSubmit, formState: { errors, isValid } } = useForm<IOrderForm>({
        mode: 'onChange',
        defaultValues: { termsOfService: '', usersNumber: store.usersNumber }
    })

    const loadData = async () => {
        try {
            setLoading(true)
            const confirmationToken = searchParams.get('confirmation_token')
            if (confirmationToken) {
                await store.getUserByToken(confirmationToken)
            }

            if (!store.user.id) {
                navigate('/')
            }
            await store.getAgreementContent()
            setLoading(false)
        } catch (error: any) {
            setLoading(false)
            setMessage(error.message)
            setSnackbarProps({ severity: 'error', open: true })
        }
    }

    useEffect(() => {
        if (!searchParams.get('confirmation_token')) {
            navigate('/')
        } else {
            loadData()
        }
    }, [])

    const onSubmitOrderSummary = async (values: IOrderForm) => {
        try {
            setLoading(true)
            await store.submitOrderSummary()
            await refreshCurrentUser()

            navigate('/billing-information', { replace: true })
            setLoading(false)
        } catch (error: any) {
            console.log(error.response.data.errors);
            Object.keys(error.response.data.errors).forEach(key => {
                let value = error.response.data.errors[key];
                console.log(key, value);

                let reason = value[0];

                setMessage(`${capitalize(key.replace(/_/g, ' '))} ${reason}`)
            });

            setLoading(false)
            setSnackbarProps({ severity: 'error', open: true })
        }
    }

    const handleClose = (_evt: Event | React.SyntheticEvent<any, Event>) => {
        setSnackbarProps(state => ({ ...state, open: false }))
    }

    const setNumberOfAdditionalUser = (usersNumber: number) => {
        store.setAdditionalUsers(usersNumber)
    }

    const increaseUsersNumber = () => {
        setNumberOfAdditionalUser(store.usersNumber + 1)
    }

    const decreaseUsersNumber = () => {
        setNumberOfAdditionalUser(store.usersNumber - 1)
    }

    return (
        <PublicLayout>
            <OrderPaper>
                <TitleWrapper>
                    <Title>
                        Order Summary
                    </Title>
                </TitleWrapper>
                <ContentWrapper>
                    <form onSubmit={handleSubmit(onSubmitOrderSummary)}>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>Service Plan Selected</Typography>
                            </div>
                            <div>
                                <Typography><b>AutoPylot Unlimited</b></Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>User Fee (per month/per user)</Typography>
                            </div>
                            <div>
                                <Typography>{currencyFormat(store.pricePerUser)}</Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <SubsriptionDetailsBox>
                            <div>
                                { store.percentOff > 0 && <Typography fontWeight={700}>You've received {store.percentOff}% discount on monthly user fee through {store.discountProvider}.</Typography> }
                            </div>
                            <div>
                                <Typography>&nbsp;</Typography>
                            </div>
                        </SubsriptionDetailsBox>

                        <hr/>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>Selected Service Plan includes:</Typography>
                            </div>
                            <div>
                                <Typography>&nbsp;</Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>Users (minimum 5)</Typography>
                            </div>
                            <div>
                                <UsersNumberField
                                    size="small"
                                    value={store.usersNumber}
                                    InputProps={{
                                      readOnly: true,
                                      endAdornment: (
                                          <StyledInputAdornment position="end">
                                              <StyledIconButton className='increaseUsersNumber' onClick={(e) => { increaseUsersNumber() }}>
                                                  <KeyboardArrowUpIcon />
                                              </StyledIconButton>
                                              <StyledIconButton className='decreaseUsersNumber' disabled={store.usersNumber === 5} onClick={(e) => { decreaseUsersNumber() }}>
                                                  <KeyboardArrowDownIcon />
                                              </StyledIconButton>
                                          </StyledInputAdornment>
                                      )
                                    }}
                                />
                            </div>
                        </SubsriptionDetailsBox>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>Dedicated AutoPylot number per user</Typography>
                            </div>
                            <div>
                                <Typography><CheckIcon fontSize="small" /></Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>Unlimited Minutes</Typography>
                            </div>
                            <div>
                                <Typography><CheckIcon fontSize="small" /></Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>Automatic logging of activities into Microsoft Dynamics</Typography>
                            </div>
                            <div>
                                <Typography><CheckIcon fontSize="small" /></Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>Free trial for 30 days</Typography>
                            </div>
                            <div>
                                <Typography><CheckIcon fontSize="small" /></Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography>AutoPylot App for Microsoft Dynamics environment</Typography>
                            </div>
                            <div>
                                <Typography><CheckIcon fontSize="small" /></Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <hr/>
                        <SubsriptionDetailsBox>
                            <div>
                                <Typography><b>Subscription Fee (billed monthly)</b></Typography>
                            </div>
                            <div>
                                <Typography><b>{currencyFormat(store.totalPrice)}/Month</b></Typography>
                            </div>
                        </SubsriptionDetailsBox>
                        <hr/>
                        <AgreementPaper>
                            <div dangerouslySetInnerHTML={{ __html: store.agreementContent }} />
                        </AgreementPaper>

                        <ButtonRow style={{ marginTop: 0 }}>
                            <FormControlLabel
                                control={
                                    <Checkbox {...register('termsOfService', { required: 'Required Field' })} />
                                }
                                label='I have read and accept the Knowme Client Agreement'
                            />
                            {!!errors?.termsOfService && <FormHelperText sx={{ textAlign: 'center' }} error>{!!errors?.termsOfService?.message ? errors?.termsOfService?.message : 'Required Field'}</FormHelperText>}
                        </ButtonRow>
                        <ButtonRow style={{ marginTop: 10 }}>
                            {loading ? <CircularProgress /> : <Button type='submit' disabled={!isValid}>I Agree</Button>}
                        </ButtonRow>
                    </form>
                </ContentWrapper>
            </OrderPaper>
            <Snackbar open={snackbarProps.open} message={message} severity={snackbarProps.severity} autoHideDuration={6000} onClose={handleClose} />
        </PublicLayout>
    )
})

export default OrderSummary
